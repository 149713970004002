import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import { startTransition, StrictMode, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';
import * as Sentry from '@sentry/remix';

// TODO: abstract
function nestedPropsExist(obj: Record<string, any>, ...props: string[]): boolean {
	if (!obj || typeof obj !== 'object') {
		return false;
	}

	// No props were passed
	if (props.length === 0) {
		return false;
	}

	// Assign first item to variable `prop` AND create new array with remaining items called rest
	const [prop, ...rest] = props;

	// Call again recursively with list of remaining props to check (`rest`)
	if (prop in obj) {
		return nestedPropsExist(obj[prop], ...rest);
	}

	// If a `prop` is not found...then we are done, it fails the exhaustive check
	return false;
}

/* eslint-disable no-underscore-dangle */
if (nestedPropsExist(window, '__BVC__', 'ENV') && window.__BVC__.ENV === 'production') {
	Sentry.init({

		dsn: window.__BVC__.SENTRY_DSN_PUBLIC,
		environment: window.__BVC__.ENV,
		tracesSampleRate: Number(window.__BVC__.SENTRY_TRACES_SAMPLE_RATE),
		integrations: [
			Sentry.browserTracingIntegration({
				useEffect,
				useLocation,
				useMatches,
			}),
			Sentry.consoleIntegration(),
		],
	});
}
/* eslint-enable */

function hydrate() {
	startTransition(() => {
		hydrateRoot(
			document,
			<StrictMode>
				<RemixBrowser />
			</StrictMode>
		);
	});
}

if (typeof requestIdleCallback === 'function') {
	requestIdleCallback(hydrate);
} else {
// Safari doesn't support requestIdleCallback
// https://caniuse.com/requestidlecallback
	setTimeout(hydrate, 1);
}
